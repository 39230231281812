import React, { useEffect } from 'react'
import ContactUS from '../Components/Layout/ui/ContnactUs/ContactUs'
import { useSelector } from 'react-redux'

export default function ContactUsPage() {
    const lang = useSelector(state=>state.language.language)
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
return (
    <div className='section main-layout contactUs'>
        <div className='section-header'>
            <h1><span>{lang==='en'?'Contact':'تواصل'}</span> {lang==='en'?'us now':'معنا الآن'}</h1>
            <h3>{lang==='en'?'Lorem ipsum dolor sit amet consectetur. Tristique turpis dui eget ipsum':'منصة واحدة لتلبية احتياجات الأمهات بشكل شاملمنصة واحدة لتلبية احتياجات الأمهات '}</h3>
        </div>      
        <ContactUS/>
    </div>
)
}