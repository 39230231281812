import React, { useState } from 'react'
import './systemLogin.css'
import { useSelector } from 'react-redux'
import CountryFlag from 'react-country-flag';
export default function Input({ label,type ,...props}) {
  const lang = useSelector(state=> state.language.language)
  const [showPassword, setShowPassword] = useState(false)
  return (
    <>
    {type === 'password'?
        <div className='input-group'>
            <label htmlFor={props.name}>{label}</label>
            <input type={showPassword === true?'text':'password'} id={props.name} {...props}/>
            <span onClick={()=> setShowPassword(!showPassword)} className={`password-toggle-icon ${lang==='en'?'password-toggle-icon-en':'password-toggle-icon-ar'}`}><i className={`${showPassword === true?'fa-eye':'fa-eye-slash'} fas`}></i></span>
        </div>
    :(props?.name === 'mobileNumber'?
      <div className='input-group'>
              <label type='text' htmlFor='phone'>{label}</label>
              <div style={{
              position:'relative',
              }}>
                  <div style={{width:"100%"}}>
                  <input className={`${lang==='en'?'phone-number-input':'phone-number-input-ar'}`} name={props.name} id={props.name} {...props} />
                  </div>
                  <div style={lang==='en'?{
                      position:'absolute',
                      // top:14,
                      left:15,
                      display:'flex',
                      alignItems:'center',
                      color:'#ACACAC',
                      top: "50%", 
                      transform: 'translateY(-50%)',
                  }:{
                    position:'absolute',
                    right:15,
                    top: "50%",
                    transform: 'translateY(-50%)',
                    display:'flex',
                    alignItems:'center',
                    color:'#ACACAC',
                  }}>
                    <CountryFlag countryCode={props?.flag?props.flag:'EG'} svg />
                  {props?.phonecode?props.phonecode.startsWith('+') ? props.phonecode : `+${props.phonecode}`:'+20'} 
                </div>
            </div>
        </div>  
    :
        <div className='input-group'>
            <label style={{color:props.color?props.color:"black"}} type={type} htmlFor={props.name}>{label}</label>
            <input id={props.name} {...props}/>
        </div>
    )
    }
    </>
  )
}
