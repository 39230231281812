import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {  get_cookie } from "../../Client/token";


export const fetchUserData = createAsyncThunk('userData/fetchUserData',async()=>{
    try {
        let token = get_cookie('user')
        if(token){
            let response = await axios.get(`${process.env.REACT_APP_API_URL}providers/api/providers/get-provider`,{
                headers:{
                    "Authorization":`${token}`,
                }
            })
            if(response.status === 200){
                return response.data;
            }
        }
    } catch (err) {
        if(err.response.status===401){
            console.log('Invalid User');
        }
        
    }
})
const userDataSlice = createSlice({
    name: "userData",
    initialState: {
        data: undefined,
        status: null
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserData.fulfilled, (state, { payload }) => {
            state.data = payload
          state.status = 'success';
        });
        builder.addCase(fetchUserData.pending, (state, { payload }) => {
            state.data = payload
          state.status = 'loading';
        });
        builder.addCase(fetchUserData.rejected, (state,  { payload }) => {
            state.data = payload
          state.status = 'rejected';
        });
      },
})

export default userDataSlice.reducer;


