import React, { useEffect } from 'react'
import HeaderBody from '../Components/Layout/ui/Header/HeaderBody';
import HeaderBrands from '../Components/Layout/ui/Header/HeaderBrands';

export default function HomePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <div className='header-bg main-layout main-layout-bg'>
        <HeaderBody/>
        <HeaderBrands/>
    </div>
    <>
        {/* <WhyJoinUs/> */}
    </>
    </>
  )
}
