import React from 'react'
import './Footer.css'
import logo from '../../../../Images/logo.svg'
import logoAr from '../../../../Images/logo-ar.svg'
import facebook from '../../../../Images/media/facebook.svg'
import instagram from '../../../../Images/media/instagram.png'
import linkedin from '../../../../Images/media/linkedin.svg'
import tiktok from '../../../../Images/media/tiktok.svg'
import whatsapp from '../../../../Images/media/whatsapp.svg'
import youtube from '../../../../Images/media/youtube.svg'

import googlePlay from '../../../../Images/payments/google-play.png'
import appGalary from '../../../../Images/payments/app-galary.png'
import appStore from '../../../../Images/payments/app-store.png'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
export default function Footer() {
    const lang = useSelector(state=>state.language.language)
  return (
    <div className='footer-layout'>
    <div className="footer">
        <div className='footer-c'>
             <div>
                <img src={lang==='en'?logo:logoAr} alt="" />
             </div>
             <div className='brief'>
                {lang ==='en'?
                <p>The Momies application is a team of supporters for every woman and mother who seeks to achieve the best for herself, her home, and her children. Therefore, we designed this application for you with all care to serve all aspects of life that you need to combine your personal life and interest in developing yourself. <Link>... more</Link></p>
                :    
                <p>تطبيق ماميز هو فريق من الداعمين لكل إمرأة وأم تسعى لتحقيق الأفضل لنفسها وبيتها وأولادها ولذلك صممنا لكي هذا التطبيق بكل عناية ليخدم كل جوانب الحياة  <Link>... المزيد</Link> </p>
                }
             </div>
             <div className='media'>
                <div>
                    <a href="https://www.facebook.com/" target='_blank'>
                        <img src={facebook} alt="" />
                    </a>
                </div>
                <div>
                    <a href="https://www.instagram.com/" target='_blank'>
                        <img src={instagram} alt="" />
                    </a>
                </div>
                <div>
                    <a href="https://www.facebook.com/" target='_blank'>
                        <img src={linkedin} alt="" />
                    </a>
                </div>
                <div>
                    <a href="https://www.facebook.com/" target='_blank'>
                        <img src={tiktok} alt="" />
                    </a>
                </div>
                <div>
                    <a href="https://www.facebook.com/" target='_blank'>
                        <img src={whatsapp} alt="" />
                    </a>
                </div>
                <div>
                    <a href="https://www.facebook.com/" target='_blank'>
                        <img src={youtube} alt="" />
                    </a>
                </div>
             </div>
        </div>
        <div className='links'>
            <h3>{lang==='en'?'Momies’ services':'خدمات الماميز'}</h3>
            {lang==='en'?
            <ul>
                <li>Features</li>
                <li>Pricing</li>
                <li>Pricing</li>
                <li>Pricing</li>
                <li>Pricing</li>
                <li>Pricing</li>
                <li>Reviews</li>
            </ul>
            :
            <ul>
                <li>من نحن</li>
                <li>ملتقى الشركات</li>
                <li>الوظائف</li>
                <li>الشروحات</li>
                <li>المؤتمرات</li>
                <li>الخطط والاسعار</li>
                <li>الترويج والاعلانات</li>
                <li>الدعم الفني</li>
                <li>المميزات</li>
            </ul>
            }
        </div>
        <div className='links'>
            <h3>{lang==='en'?'Service providers services':'خدمات مقدمي الخدمات'}</h3>
            {lang==='en'?
            <ul>
                <li>About</li>
                <li>Contact us</li>
                <li>Blog</li>
            </ul>
            :    
            <ul>
                <li>الدعم الفني</li>
                <li>المميزات</li>
                <li>الخطط والاسعار</li>
            </ul>
            }
        </div>
        
        <div className='links'>
            <h3>{lang==='en'?'Vzadi services':'خدمات ڤزادي'}</h3>
            {lang==='en'?
            <ul>
                <li>Chat support</li>
                <li>Help center</li>
                <li>Help center</li>
                <li>Help center</li>
                <li>Help center</li>
                <li>Help center</li>
            </ul>
            :    
            <ul>
                <li>الاستضافة</li>
                <li>احصل على متجرك</li>
                <li>تطبيقات الموبايل</li>
                <li>انظمة الشركات</li>
                <li>المواقع الالكترونية</li>
                <li>طور نظامك</li>
                <li>الدعم الفني</li>
            </ul>
        }
        </div>
        <div className='links'>
            <h3>{lang==='en'?'Downloads':'تحميل'}</h3>
            <ul className='payments'>
                <li><img src={appStore} alt="" /></li>
                <li><img src={appGalary} alt="" /></li>
                <li><img src={googlePlay} alt="" /></li>
            </ul>
        </div>
    </div>
    <div className='footer-line'></div>
    <div className='footer-bottom' dir='ltr'>
        <div>
            <p>Copyright © 2024 <a href='#'>Vzadi</a> | All Rights Reserved </p>
        </div>
        <div>
            {lang==='en'?
            <p><a href="" className='a'>Terms and Conditions</a> | <a className='a' href="">Privacy Policy</a></p>
            :
            <p><a href="" className='a'>الأحكام والشروط</a> | <a className='a' href="">سياسة الخصوصية</a></p>
            }
        </div>
    </div>
    </div>
  )
}
