import React from 'react'
import Slider from "react-slick";
import facebook from '../../../../Images/brands/Logos for Ahmed Mosaad-01.png'
import google from '../../../../Images/brands/Logos for Ahmed Mosaad-02.png'
import Pinterest from '../../../../Images/brands/Logos for Ahmed Mosaad-03.png'
import Twitch from '../../../../Images/brands/Logos for Ahmed Mosaad-04.png'
import Webflow from '../../../../Images/brands/Logos for Ahmed Mosaad-05.png'
import Youtube from '../../../../Images/brands/Logos for Ahmed Mosaad-06.png'
import { useSelector } from 'react-redux';
export default function HeaderBrands() {
  const lang = useSelector(state=>state.language.language)
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows:false,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: false
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 1
              }
            }
            
          ]
      };
  return (
    <div className='brand-body'>
        <h5>{lang==='en'?'Join the best service providers list':'انضم لافضل قائمة مقدمي خدمات'}</h5>
        <Slider {...settings}>
          <div className='brand'>
            <img src={facebook} alt="" />
          </div>
          <div className='brand'>
            <img src={google} alt="" />
          </div>
          <div className='brand'>
            <img src={Pinterest} alt="" />
          </div>
          <div className='brand'>
            <img src={Twitch} alt="" />
          </div>
          <div className='brand'>
            <img src={Webflow} alt="" />
          </div>
          <div className='brand'>
            <img src={Youtube} alt="" />
          </div>
          
        </Slider>
    </div>
  )
}
