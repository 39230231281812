import React from 'react'

export default function SubmitButton({isLoading,
  disabled,
  name,
  ...props}) {
  return (
    <div className='input-group'>
        <button type='submit' disabled={isLoading || disabled} {...props}>{isLoading? <i className='fa fa-spinner fa-spin'></i>:name}</button>
    </div>
  )
}
