export function save_token_in_cookie(token) {
  // Create expiry date
  var now = new Date();
  var time = now.getTime();
  // set expiry date after 1 day
  time += 24 * 3600 * 1000;
  now.setTime(time);
  // Create cookie
  document.cookie = `user=${token}; expires=${now.toUTCString()}; path=/`;
}

export function delete_cookie(name, path, domain) {
  if (get_cookie(name)) {
    document.cookie =
      name +
      "=" +
      (path ? ";path=" + path : "") +
      (domain ? ";domain=" + domain : "") +
      ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
}

export function get_cookie(key) {
  let result;
  // eslint-disable-next-line no-cond-assign
  return (result = new RegExp(
    "(?:^|; )" + encodeURIComponent(key) + "=([^;]*)"
  ).exec(document.cookie))
    ? result[1]
    : null;
}
