import React from "react";
import card from '../../../../Images/profile/card.png'
import { useSelector } from "react-redux";

export default function JobCard() {
  const lang = useSelector(state=>state.language.language)
  return (
    <div className="h-card">
        <div className="card">
        <div className="card-level">{lang==='en'?'Mid level':'المستوى المتوسط'}</div>
        <img className="card-img" src={card} alt="" />
        <div className="card-body">
            <div className="card-title">{lang==='en'?'Digital Marketing':'التسويق الرقمي'}</div>
            <p className="card-text">{lang==='en'?'Vzadi company':'شركة فزادي'}</p>
            <div className="card-btn">
            {lang==='en'?'View details':'عرض التفاصيل'}
            <i className={`${lang==='en'?'fa fa-arrow-right':'fa fa-arrow-left'}`}></i>
            </div>
        </div>
        </div>
    </div>
  );
}
