import React from 'react'
import './Header.css'
import gif from '../../../../Images/gif.gif';
import shapeCircle from '../../../../Images/utilis/Elements-organic-shape-circle--loading-spin.png'
import shapeLeaves from '../../../../Images/utilis/Elements-organic-shape-leaves-nature-vine.png'
import shapeHeart from '../../../../Images/utilis/Elements-organic-shape-heart.png'
import shapeHeart1 from '../../../../Images/utilis/Elements-organic-shape-heart1.png'
import shapeCircleAr from '../../../../Images/utilis/Elements-organic-shape-circle--loading-spin-ar.png'
import shapeLeavesAr from '../../../../Images/utilis/Elements-organic-shape-leaves-nature-vine-ar.png'
import shapeLine from '../../../../Images/utilis/Elements-organic-shape-line-dash-3.png'
import shapeLineAr from '../../../../Images/utilis/Elements-organic-shape-line-dash-3-ar.png'
import shapeLineResponsive from '../../../../Images/utilis/Elements-organic-shape-line-dash-3-responsive.png'
import shapeLineArResponsive from '../../../../Images/utilis/Elements-organic-shape-line-dash-3-ar-responsive.png'
import shapeHeartAr from '../../../../Images/utilis/Elements-organic-shape-heart-ar.png'
import shapeHeart1Ar from '../../../../Images/utilis/Elements-organic-shape-heart1-ar.png'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
export default function HeaderBody() {
  const lang = useSelector(state=>state.language.language)
  return (
    <div className='h-body'>  
      <div className='h-body-content'>
        <div>
          <div className='content'>
            <div className='head-paragraph'>
              {lang === 'en' ? 
              <h2>Empowering <span>Moms</span> Everywhere,  All in One Place!</h2>
              :  
              <h2>منصة واحدة لتلبية احتياجات <span>الأمهات</span> بشكل شامل!</h2>
              
            }
              <div className={lang==='en'?'share-leave':'share-leave-ar'}><img src={lang==='en'?shapeLeaves:shapeLeavesAr} alt="" /></div>  
              <div className={lang==='en'?'share-heart':'share-heart-ar'}><img src={lang==='en'?shapeHeart:shapeHeartAr} alt="" /></div>  
              <div className={lang==='en'?'share-heart1':'share-heart1-ar'}><img src={lang==='en'?shapeHeart1:shapeHeart1Ar} alt="" /></div>  
            </div>
            <div>
              {lang === 'en'?
              <p>The Momies application is a team of supporters for every woman and mother who seeks to achieve the best for herself, her home, and her children. Therefore, we designed this application for you with all care to serve all aspects of life that you need to combine your personal life and interest in developing yourself. </p>
              :
              <p>تطبيق ماميز هو فريق من الداعمين لكل إمرأة وأم تسعى لتحقيق الأفضل لنفسها وبيتها وأولادها ولذلك صممنا لكي هذا التطبيق بكل عناية ليخدم كل جوانب الحياة التي تحتاجينها للدمج بين حياتك الشخصية والاهتمام بتطوير ذاتك .</p>
              }
            </div>
            <div className='buttons'>
              <button className='btn-main'><Link to='/service-provider-features'>{lang ==='en'?'Service provider':'مقدم خدمات'}</Link></button>
              <button className='btn-outline-main'> <Link to='/momies-features'>{lang ==='en'?'Momi':'مامي'}</Link></button>
            </div>
          </div>
        </div>
      </div>
      <div className='h-body-content'>
        <div>
          <div className='img'>
            <img src={gif} alt="" />
            <div className={lang==='en'?'shape-circle':'shape-circle-ar'}>
              <img src={lang==='en'?shapeCircle:shapeCircleAr} alt="" />
            </div>
            <div className={lang==='en'?'shape-line':'shape-line-ar'}>
              <img src={lang==='en'?shapeLine:shapeLineAr} alt="" />
            </div>
            <div className={lang==='en'?'shape-line-res':'shape-line-ar-res'}>
              <img src={lang==='en'?shapeLineResponsive:shapeLineArResponsive} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
