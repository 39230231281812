import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import Momies from '../Components/Layout/ui/Features/Momies';

export default function MomiesFeaturesPage() {
  const lang = useSelector(state=>state.language.language)
  useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  return (
    <div className='section featuers main-layout main-layout-bg'>
        <div className='section-header'>
            <h1><span>{lang==='en'?'Momies':'الامهات'}</span></h1>
        </div>
        <Momies/>        
    </div>
  )
}
