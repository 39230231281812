import React, { useEffect, useState } from 'react'
import SubmitButton from '../../Components/Layout/ui/SystemLogin/SubmitButton'
import { useSelector } from 'react-redux'
import PinInput from 'react-pin-input'

export default function VerifyOtpPage() {
  const lang = useSelector(state=> state.language.language)
  const [code, setCode] = useState(null)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='form'>
      <div className='form-package'>
        <form  className='form-group' action="">
          <div className='inputs-group' style={{textAlign:'center'}}>
            <h3>{lang==='en'?'OTP Verification':'رمز التأكيد OTP'}</h3>
            <div>
              <h5>{lang==='en'?'Enter the OTP sent to':'ادخل رمز التأكيد المرسل للرقم'} <div dir='ltr'>+201049586934</div></h5>
              <p dir='ltr'></p>
            </div>
            <PinInput
            length={4}
            onChange={(value) => {
              setCode(value);
            }}
            type="numeric"
            inputMode="number"
            placeholder='0'
            inputStyle={{
              borderColor: '#9CA2AC',
              border:'none',
              borderBottom: `${code?'solid 2px #2743FD':'solid 2px #9CA2AC'}`,
              margin: '0.5rem',
              fontSize:'20px',
              fontWeight:'600'
            }}
            autoSelect={true}
            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
          />
            <h6>{lang==='en'?`Didn’t you receive the OTP?`:'لم تتلق رمز التأكيد OTP؟'} <span>{lang==='en'?'Resend OTP':'اعادة ارسال رمز التأكيد'}</span></h6>  
          </div>
          <SubmitButton name={lang==='en'?'Verify':'تأكيد'} />
        </form>
      </div>
    </div>
  )
}
