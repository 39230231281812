import React, { useEffect, useState } from 'react'
import Input from '../../Components/Layout/ui/SystemLogin/Input'
import shapeSparkle from '../../Images/utilis/Elements-organic-shape-abstract-sparkle-dash.svg'
import SubmitButton from '../../Components/Layout/ui/SystemLogin/SubmitButton'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { useFormData } from './../../hooks/use-form-data-hook';
import useCustomMutation from './../../hooks/use-custom-mutation';
import { client } from './../../Client/apis-method';
import { AppValidators } from '../../Client/validation'
import { CountrySelector } from '../../Components/Layout/ui/SystemLogin/Select'
import { useCountry } from '../../data/vistor'
import { toast } from 'react-toastify'

export default function RegisterPage() {
  const lang = useSelector(state=>state.language.language)
  const navigate = useNavigate();

  const [selectedCountry, setSelectedCountry] = useState('');
  const [flag, setFlag] = useState('');
  const [phoneCode, setPhoneCode] = useState('');
  const { data: countries } = useCountry();


  useEffect(() => {
    if ( selectedCountry) {
      const selectedCountryData = countries.find(
        (country) => country.id === parseInt(selectedCountry)
      );
      if (selectedCountryData) {
        const flag = selectedCountryData.iso2 || '';
        setFlag(flag);
        setPhoneCode(selectedCountryData.phone_code)
      }
    }
  }, [selectedCountry, countries]);



  const register= useFormData({
    firstName: '',
    lastName: '',
    country:'',
    email:'',
    mobileNumber:'',
    password:'',
    confirmPassword:''
  });
  const handleCountryChange = (selectedValue) => {
    setSelectedCountry(selectedValue.target.value);
    register.handleChange(selectedValue)
  };
  const registerMutation = useCustomMutation({
    mutationFn: client.auth.register,
    onSuccess: (data) => {
      if(data.status === 200){
        navigate(`/verify-code/${data.userId}/register`);
      }
      else if (data.status === 4002) {
        navigate(`/verify-code/${data.userId}/register`);
      }
      else if (data.status === 4003){
        navigate(`/upload-document/${data.userId}`);
      }
      else if (data.status === 4004 || 4005){
        navigate('/login')
      }
    },
  });
  const RegisterSubmit = (data) => {
    try {
      AppValidators.firstName(data.firstName)
      AppValidators.lastName(data.lastName)
      AppValidators.required(data.mobileNumber , 'phone')
      AppValidators.email(data.email);
      AppValidators.password(data.password);
      if(data.password !== data.confirmPassword){
        return toast.error('Password and Confirm Password are not the same')
      }
      registerMutation.mutate({...data, mobileNumber: (phoneCode.startsWith('+') ? phoneCode : `+${phoneCode}`)+data.mobileNumber,country: parseInt(data.country)});
    } catch (error) {
      toast.error(error.message);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='form'>
      <div className='form-package'>
        <div className='group-buttons'>
          <div>
            <button className='selected'>{lang==='en'?'Register':'انشاء حساب'}</button>
            <button className='not-selected'><Link to='/login'>{lang==='en'?'Login':'تسجيل دخول'}</Link></button>
          </div>
            <img className='shape-sparkle' src={shapeSparkle} alt="" />
        </div>
        <h5>{lang==='en'?'Lorem Ipsum is simply dummy text of the printing and typesetting industry.':'نصة واحدة لتلبية احتياجات الأمهات بشكل شامل منصة واحدة لتلبية احتياجات الأمهات بشكل شامل منصة بشكل'}</h5>
        <form className='form-group' onSubmit={register.handleSubmit(RegisterSubmit)}>
          <div className='inputs-group'>
            <Input {...register.register('firstName')} label={lang==='en'?'First name':'الاسم الاول'} name='firstName' placeholder={lang==='en'?'Enter your First name':'ادخل الاسم الاول'} type='text'/>
            <Input {...register.register('lastName')} label={lang==='en'?'Last name':'اسم العائلة'} name='lastName' placeholder={lang==='en'?'Enter your Last name':'ادخل اسم العائلة'} type='text'/>
            <Input {...register.register('email')} label={lang==='en'?'Email address':'بريدك الالكتروني'} name='email' placeholder={lang==='en'?'Enter your Email Address':'ادخل بريدك الالكتروني'} type='email'/>
            <CountrySelector
              options={countries}
              label={lang==='en'?'Country':'اختار الدولة'}
              onChange={handleCountryChange}
            />
            <Input {...register.register('mobileNumber')} flag={flag} phonecode={phoneCode} label={lang==='en'?'Phone number':'رقم التليفون'} name='mobileNumber' placeholder={lang==='en'?'- - - - - - - - - - - - - - - -':'- - - - - - - - - - - - - - - -'} type='text'/>

            <Input {...register.register('password')} label={lang==='en'?'Password':'كلمة المرور'} name='password' placeholder={lang==='en'?'Enter you password':'ادخل كلمة المرور'} type='password'/>
            <div className='remember-me'>
            <Input {...register.register('confirmPassword')}  label={lang==='en'?'Confirm Password':'تاكيد كلمة المرور'} name='confirmPassword' placeholder={lang==='en'?'Enter same password':'ادخل كلمة المرور اخري'} type='password'/>
              <div>
                  <div>
                    <input type="checkbox" required id="remember-me" />{" "}
                      {lang==='en'?
                    <label htmlFor="remember-me">
                      Accept <Link to='terms'>terms</Link> and <Link to='condition'>conditions</Link>
                    </label>
                      :
                    <label htmlFor="remember-me">
                      الموافقة على <Link to='terms'>الشروط</Link> و <Link to='condition'>الاحكام</Link>
                    </label>
                      }
                  </div>
              </div>
            </div>
          </div>
          <SubmitButton name={lang==='en'?'Register':'انشاء حساب'} isLoading={registerMutation.isPending}/>
        </form>        
      </div>
    </div>
  )
}
