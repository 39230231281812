import React, { useEffect } from 'react'
import Pricing from '../Components/Layout/ui/Pricing/Pricing'

export default function PricingPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
        <Pricing/>
        {/* <PricingDetails/> */}
    </>
  )
}
