import React from 'react'
import './HowItWork.css'
import createAccount from '../../../../Images/HowitWork/CreateAccount.png'
import GetVerify from '../../../../Images/HowitWork/GetVerify.png'
import Subscribe from '../../../../Images/HowitWork/Subscribe.png'
import { useSelector } from 'react-redux'
import { client } from '../../../../Client/apis-method'
import { API_ENDPOINTS } from '../../../../Client/end-points'
import { useQuery } from '@tanstack/react-query'
export default function HowItWork() {
    const lang = useSelector(state=>state.language.language)
    const { isPending, data, isError, error } = useQuery({
        queryKey: [API_ENDPOINTS.WEB_STEPS],
        queryFn:  client.web.getSteps,
      });
return (
    <div className='how-it-work main-layout'>
        <div>
        {data?.data?.map((step) => (
            <div key={step._id} className={`section-content ${step.stepNum==="1"?'n1':''} ${step.stepNum==="2"?'reverse':''}`}>
                <div className='desc'>
                    {step.steps.map(s => (
                        <div key={s._id}>
                            <h4><span>#{s.step}</span>{lang === 'en' ? s.title.english : s.title.arabic}</h4>
                            <p className={lang === 'en' ? (s.step==="2"?'create-account':'get-verified') : (s.step==="2"?'create-account-ar':'get-verified-ar ')}>
                                {lang === 'en' ? s.desc.english : s.desc.arabic} 
                            </p>
                        </div>
                    ))}
                </div>
                <div className={`img ${step.stepNum ==="2"?'n2':''}`}>
                    <img src={`http://providers.momies.net/api/${step.image}`} alt="" />
                </div>
            </div>
        ))}
        </div>
    </div>
  )
}
