import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
};

const ResendCode = ({ secondsCounter }) => {
  const [codeResend, setCodeResend] = useState(false);
  const [seconds, setSeconds] = useState(secondsCounter ? secondsCounter : 0);
  const params = useParams();
  const lang = useSelector((state) => state.language.language);

  const resendCode = async () => {
    try {
      let response = await axios.post(
        `http://providers.momies.net/providers/api/providers/resend-code/${params?.id}`,
        {
          type:params.from === 'recovery'?'reset':'',
        },
        {
          headers: {
            apikey: 'w',
          },
        }
      );
      if (response?.status === 200) {
        toast.success('code sent in your email');
      }
    } catch (err) {
      if (err?.response?.status === 400) {
        setSeconds(err.response.data.canResendAfter);
      }
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds === 0) {
        clearInterval(timer);
        setCodeResend(true);
      } else {
        setSeconds((prev) => prev - 1);
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [seconds, setCodeResend, setSeconds]);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {params.from !== 'recovery' && seconds === 0 ? (
        <span type='button' onClick={resendCode} className='resend-code'>
          {lang === 'en' ? 'Resend Code ' : 'اعادة ارسال'}
        </span>
      ) : (
        ''
      )}
      {seconds !== 0 && (
        <span
          aria-disabled={!codeResend}
          style={{
            color: '#548fe8',
            fontSize: '1.125rem',
            fontWeight: '700',
          }}
        >
          Resend Code After ({formatTime(seconds)})
        </span>
      )}
    </div>
  );
};

export default ResendCode;
