import React from 'react'
import { useSelector } from 'react-redux'
export default function FrequentlyQuestion() {
    const lang = useSelector(state=>state.language.language)
  return (
    
    <div className='main-layout frequentlyQuestion'>
        <div className='head'>
            <h3>{lang==='en'?'Frequently Asked Questions':'الاسئلة الاكثر تكرارًا'}</h3>
        </div>
        <div className='body'>
            <div className='content'>
                <div>
                    <details>
                        <summary>
                            {lang==='en'?'How to register?':'كيفية التسجيل؟'}
                        </summary>
                            <p>
                            {lang==='en'?'':'قم بإدخال أسمك كاملاً ثم إدخل البريد الإلكتروني الخاص بك وقم بوضع رقم هاتفك  بعد ذلك ضع كلمة المرور ثم تحقق من رمز التأكيد الذي سيصلك على بريدك الإلكتروني ثم  تحقق من رمز التأكيد الذي سيصلك علي بريدك الإلكتروني وقم بإدخال معلومات شركتك (الأسم /الدولة /القسم / اللينك إن وجد)'}
                            </p>
                    </details>
                </div>
            </div>
            <div className='content'>
                <div>
                    <details>
                        <summary>
                            {lang==='en'?'Contact customer service?':'هل يوجد خدمات دعم فني؟'}
                        </summary>
                            <p>
                            {lang==='en'?'':'يوجد خدمات دعم فني علي مدار الساعه تستطيع التواصل معهم عن طريق بدأ محادثه داخل التطبيق او التواصل علي الخط الساخن وشرح المشكله التي تواجهك وسيتم التواصل معك و حلها في اسرع وقت.'}
                            </p>
                    </details>
                </div>
            </div>
            <div className='content'>
                <div>
                    <details>
                        <summary>
                            {lang==='en'?'How to display products?':'كيفية عرض المنتجات؟'}
                        </summary>
                            <p>
                            {lang==='en'?'':'ستجد خانات مخصصة لأضافة المنتجات وعرضها تستطيع أن تضيف أى عدد من المنتجات أو الخدمات والعروض المختلفة.'}
                            </p>
                    </details>
                </div>
            </div>
            <div className='content'>
                <div>
                    <details>
                        <summary>
                            {lang==='en'?'Is it possible to register from outside Egypt?':'هل يمكن التسجيل من خارج مصر؟'}
                        </summary>
                            <p>
                            {lang==='en'?'':'نعم يمكن'}
                            </p>
                    </details>
                </div>
            </div>
            <div className='content'>
                <div>
                    <details>
                        <summary>
                            {lang==='en'?'Contact customer service?':'التواصل مع خدمة العملاء؟'}
                        </summary>
                            <p>
                            {lang==='en'?'':'عن طريق بدأ محادثه داخل التطبيق او التواصل علي الخط الساخن.'}
                            </p>
                    </details>
                </div>
            </div>
            <div className='content'>
                <div>
                    <details>
                        <summary>
                            {lang==='en'?'Can more than one service be offered?':'هل يمكن عرض أكثر من خدمة؟'}
                        </summary>
                            <p>
                            {lang==='en'?'':'نعم يمكن نشر أى عدد تشاء من الخدمات'}
                            </p>
                    </details>
                </div>
            </div>
            <div className='content'>
                <div>
                    <details>
                        <summary>
                            {lang==='en'?'What should I do if I do not receive the confirmation code?':'ماذا أفعل في حالة عدم أستلام كود التأكيد؟'}
                        </summary>
                            <p>
                            {lang==='en'?'':'عن طريق التحقق من البريد الالكتروني الذي ادخلته او البحث عنها في ال spamاو ال junk'}
                            </p>
                    </details>
                </div>
            </div>
            <div className='content'>
                <div>
                    <details>
                        <summary>
                            {lang==='en'?'Is it possible to register for two companies with the same account?':'هل يمكن التسجيل لشركتين بنفس الحساب؟'}
                        </summary>
                            <p>
                            {lang==='en'?'':'لا يمكن، يجب عمل حساب لكل شركه أو مشروع'}
                            </p>
                    </details>
                </div>
            </div>
            <div className='content'>
                <div>
                    <details>
                        <summary>
                            {lang==='en'?'What should I do if there is no link to the pages?':'ماذا أفعل في حالة عدم وجود لينك للصفحات؟'}
                        </summary>
                            <p>
                            {lang==='en'?'':'لا يوجد مشكلة تستطيع التسجيل بدونها.'}
                            </p>
                    </details>
                </div>
            </div>
            <div className='content'>
                <div>
                    <details>
                        <summary>
                            {lang==='en'?'Is it possible to have conversations within the app?':'هل يمكن إجراء محادثات داخللا التطبيق؟'}
                        </summary>
                            <p>
                            {lang==='en'?'':''}
                            </p>
                    </details>
                </div>
            </div>
        </div>
    </div>
  )
}
