import React from 'react'
import sponsor from '../../../../Images/profile/sponsor.png'
import advertisement from '../../../../Images/profile/adver.png'
import { useSelector } from 'react-redux'

export default function ProfileRight() {
    const lang = useSelector(state=>state.language.language)
  return (
    <div className='right'>
        <div className='statistics'>
            <div className='card'>
                <div className='number'>{lang==='en'?'210':'210'}</div>
                <div className='title'>{lang==='en'?'Products':'منتجات'}</div>
            </div>
            <div className='card'>
            <div className='number'>{lang==='en'?'210':'210'}</div>
                <div className='title'>{lang==='en'?'Products':'منتجات'}</div>
            </div>
        </div>
        <div className='sponsors'>
            <div className='head-card'>
                <div className='title'>
                    <h5>{lang==='en'?'Sponsors':'الرعاة'}</h5>
                    <p>{lang==='en'?'356 Jobs recommended for you':'356 وظيفة موصى بها لك'}</p>
                </div>
                <div className='button'>
                {lang==='en'?'View all':'عرض الكل'}
                </div>
            </div>
            <div className='companies'>
                <div className='comp'>
                    <img src={sponsor} alt="" />
                    <div className='comp-title'>
                        <h5>{lang==='en'?'Graphic Designer':'مصمم جرافيك'}</h5>
                        <p>{lang==='en'?'Linkedin - CDD':'لينكد إن - سي دي دي'}</p>
                    </div>
                </div>
                <div className='comp'>
                    <img src={sponsor} alt="" />
                    <div className='comp-title'>
                        <h5>{lang==='en'?'Graphic Designer':'مصمم جرافيك'}</h5>
                        <p>{lang==='en'?'Linkedin - CDD':'لينكد إن - سي دي دي'}</p>
                    </div>
                </div>
                <div className='comp'>
                    <img src={sponsor} alt="" />
                    <div className='comp-title'>
                        <h5>{lang==='en'?'Graphic Designer':'مصمم جرافيك'}</h5>
                        <p>{lang==='en'?'Linkedin - CDD':'لينكد إن - سي دي دي'}</p>
                    </div>
                </div>
                <div className='comp'>
                    <img src={sponsor} alt="" />
                    <div className='comp-title'>
                        <h5>{lang==='en'?'Graphic Designer':'مصمم جرافيك'}</h5>
                        <p>{lang==='en'?'Linkedin - CDD':'لينكد إن - سي دي دي'}</p>
                    </div>
                </div>
            </div>
        </div>
        <div className='advertisement'>
            <div className='head-card'>
                <div className='title'>
                    <h5>{lang==='en'?'Sponsors':'الرعاة'}</h5>
                    <p>{lang==='en'?'356 Jobs recommended for you':'356 وظيفة موصى بها لك'}</p>
                </div>
                <div className='button'>
                {lang==='en'?'View all':'عرض الكل'}
                </div>
            </div>
            <div className='adverts'>
                <div className='adv'>
                    <img src={advertisement} alt="" />
                    <div className='adv-content'>
                        <div className='contenu'>
                            <h6>{lang==='en'?'19 September. 2023 19:45':'19 سبتمبر. 2023 19:45'} </h6>
                            <h5>{lang==='en'?'Apéro Club Exchange langages':'اللغات Apéro Club Exchange'}</h5>
                            <h4>{lang==='en'?'Afterwork Paris . Paris':'ما بعد العمل باريس. باريس'} </h4>
                        </div>
                        <div className='info'>
                            <h5>{lang==='en'?'17 participants':'17 مشاركا'}</h5>
                            <div className='icons'>
                                <i className='fa fa-share-nodes'></i>
                                <i className='fa fa-star'></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='adv'>
                    <img src={advertisement} alt="" />
                    <div className='adv-content'>
                        <div className='contenu'>
                            <h6>{lang==='en'?'19 September. 2023 19:45':'19 سبتمبر. 2023 19:45'} </h6>
                            <h5>{lang==='en'?'Apéro Club Exchange langages':'اللغات Apéro Club Exchange'}</h5>
                            <h4>{lang==='en'?'Afterwork Paris . Paris':'ما بعد العمل باريس. باريس'} </h4>
                        </div>
                        <div className='info'>
                            <h5>{lang==='en'?'17 participants':'17 مشاركا'}</h5>
                            <div className='icons'>
                                <i className='fa fa-share-nodes'></i>
                                <i className='fa fa-star'></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
