import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import Momies from '../Components/Layout/ui/Features/Momies';
import Provider from '../Components/Layout/ui/Features/Provider';

export default function ServiceProviderFeaturesPage() {
  const lang = useSelector(state=>state.language.language)
  useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

  return (
    <div className='section featuers main-layout main-layout-bg'>
        <div className='section-header'>
            <h1>{lang==='en'?`Service Providers'`:' مقدمي الخدمات'}  <span>{lang==='en'?'features':'مميزات'}</span></h1>
            <h3>{lang==='en'?'Lorem ipsum dolor sit amet consectetur. Tristique turpis dui eget ipsum':'دلوقتي تطبيق ماميز هيوصل خدمتك أو منتجك لأكبر شريحة ممكنة... ضمن أفضل مقدمي الخدمات في المجالات المختلفة ( الطبية والتعليمية و الترفيهية وغيرهم كتير ) .. إحجز مكانك دلوقتي و أوصل بخدماتك لكل بيت ... ماتضيعش وقتك و أستفيد بخدمات تطبيق ماميز. '}</h3>
        </div>
        <Provider/>
    </div>
  )
}
