import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

const useCustomMutation = ({
  mutationFn,
  queryToInvalidate,
  onSuccess,
  onError,
}) => {
  const queryClient = useQueryClient();

  const newMutation = useMutation({
    mutationFn: mutationFn,
    onSuccess: function (data) {
      if(data){
        if ('message' in data) {
          toast.success(data.message);
        }
        if ('msg' in data) {
          toast.success(data.msg);
        }
      }
      if (queryToInvalidate) {
        queryClient.invalidateQueries(queryToInvalidate);
      }
      if (onSuccess) {
        onSuccess(data);
      }
    },
    onError: function (error) {
      if ('errors' in error) {
        if(error.errors.length>0){
          return error.errors.map(e=>
            toast.error(e.msg)
            )
        }
      }
      if ('message' in error) {
        return toast.error(error.message);
      }
      if (onError) {
        onError(error);
      }
    },
  });
  return newMutation;
};

export default useCustomMutation;
