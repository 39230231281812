import React, { useEffect } from 'react'
import Input from '../../Components/Layout/ui/SystemLogin/Input'
import shapeSparkle from '../../Images/utilis/Elements-organic-shape-abstract-sparkle-dash.svg'
import SubmitButton from '../../Components/Layout/ui/SystemLogin/SubmitButton'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import useCustomMutation from '../../hooks/use-custom-mutation'
import { client } from '../../Client/apis-method'
import { AppValidators } from '../../Client/validation'
import { useFormData } from '../../hooks/use-form-data-hook'
import { useAuth } from '../../Components/Auth/context'
import { API_ENDPOINTS } from '../../Client/end-points'
import { toast } from 'react-toastify'

export default function LoginPage() {
  const navigate = useNavigate()
  const { authorize} = useAuth()
  const login= useFormData({
    email:'',
    password:''
  });
  const loginMutation = useCustomMutation({
    mutationFn: client.auth.login,
    queryToInvalidate: { queryKey: [API_ENDPOINTS.GET_PROVIDER] },
    onSuccess: (data) => {
      if(data.status === "success"){
        authorize(data)
        localStorage.setItem('g',`"oadjvosddnsdnvsdfnsdfwsdovmsdovmsvsdmdsvsdsdvsdve"`)
        navigate('/')
      }
      else if(data.status === 4003){
        navigate(`/upload-document/${data.userId}`)
      }
      else if(data.status === 4002){
        navigate(`/verify-code/${data.userId}/register`)
      }
    },
    onError: (error) =>{
      error.errors.map(e=>toast.error(e.msg))
    } 
  });
  const LoginSubmit = (data) => {
    try {
      AppValidators.email(data.email);
      AppValidators.password(data.password);
      loginMutation.mutate(data);
    } catch (error) {
        toast.error(error.message)
    }
  };
  const lang = useSelector(state=>state.language.language);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='form'>
      <div className='form-package'>
        <div className='group-buttons'>
          <div>
            <button className='selected'>{lang==='en'?'Login':'تسجيل دخول'}</button>
            <button className='not-selected'><Link to='/register'>{lang==='en'?'Register':'انشاء حساب'}</Link></button>
          </div>
            <img className='shape-sparkle' src={shapeSparkle} alt="" />
        </div>
        <h5>{lang==='en'?'Lorem Ipsum is simply dummy text of the printing and typesetting industry.':'نصة واحدة لتلبية احتياجات الأمهات بشكل شامل منصة واحدة لتلبية احتياجات الأمهات بشكل شامل منصة بشكل'}</h5>
        <form  className='form-group' onSubmit={login.handleSubmit(LoginSubmit)}>
          <div className='inputs-group'>
          <Input {...login.register('email')} label={lang==='en'?'Email address':'بريدك الالكتروني'} name='email' placeholder={lang==='en'?'Enter your Email Address':'ادخل بريدك الالكتروني'} type='email'/>
            <div className='remember-me'>
            <Input {...login.register('password')} label={lang==='en'?'Password':'كلمة المرور'} name='password' placeholder={lang==='en'?'Enter you password':'ادخل كلمة المرور'} type='password'/>
              <div>
                  <div><Link to='/forget-password'>{lang==='en'?'Forget Password ?':'نسيت كلمة المرور؟'}</Link></div>
                  <div><Link to='/recovery-account'>{lang==='en'?'Recovery Account':'استرجاع الحساب'}</Link></div>
              </div>
            </div>
          </div>
          <SubmitButton name={lang==='en'?'Login':'تسجيل دخول'} />
        </form>
      </div>
    </div>
  )
}
