import { useState } from 'react';

export const useFormData = (defaultValues) => {
  const [data, setData] = useState(defaultValues);

  const handleChange = (e) => {
    setData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleChangeNumber = (e) => {
    setData((prev) => ({
      ...prev,
      [e.target.name]: +e.target.value,
    }));
  };

  const register = (name, type = 'string') => {
    return {
      name,
      value: data[name],
      onChange: type === 'string' ? handleChange : handleChangeNumber,
    };
  };

  const reset = () => setData(defaultValues);

  const handleSubmit = (callback) => (e) => {
    e.preventDefault();
    callback(data);
  };

  return {
    data,
    setData,
    handleChange,
    handleChangeNumber,
    register,
    reset,
    handleSubmit,
  };
};
