import React from 'react'
import ProfileLeft from '../Components/Layout/ui/Profile/ProfileLeft'
import ProfileMiddle from '../Components/Layout/ui/Profile/ProfileMiddle'
import ProfileRight from '../Components/Layout/ui/Profile/ProfileRight'

export default function ProfilePage() {
  return (
    <div className='profile-home main-layout-bg'>
        <ProfileLeft/>
        <ProfileMiddle/>
        <ProfileRight/>
    </div>
  )
}
