import { API_ENDPOINTS } from "./end-points";
import { HttpClient } from "./https-client";
import {  get_cookie } from "./token";
class Client {
  
      auth = {
        getProvider: () => {
          if(get_cookie('user')){
            return HttpClient.get(API_ENDPOINTS.GET_PROVIDER)
        }},
        login: (data) =>
          HttpClient.post(API_ENDPOINTS.LOGIN, data),
        register: (data) =>
          HttpClient.post(API_ENDPOINTS.CREATE_PROVIDER, data),
        verifyCode: ({id , ...data}) =>
          HttpClient.post(API_ENDPOINTS.VERIFY_CODE(id),data.data),
        resendCode: ({id}) =>{
          HttpClient.post(API_ENDPOINTS.RESEND_CODE(id))
        },
        uploadDocument: ({id , ...data}) =>
          HttpClient.put(API_ENDPOINTS.SIGNUP_STEP_THREE(id), data.data),
        logout: () => HttpClient.put(API_ENDPOINTS.LOGOUT),
        forget_password: (data) => {  
          return HttpClient.post(API_ENDPOINTS.FORGET_PASSWORD,data);
        },
        recover_account: (data) => {  
          return HttpClient.post(API_ENDPOINTS.RECOVER_ACCOUNT,data);
        },
        verify_recover_account: (data) => {  
          return HttpClient.post(API_ENDPOINTS.VERIFY_RECOVER_ACCOUNT,data);
        },
        reset_password: (data) => {  
          return HttpClient.put(API_ENDPOINTS.RESET_PASSWORD, data);
        },
        change_password: (data) => {
          const fd = new FormData();
          Object.entries(data).forEach(([key, value]) => fd.set(key, value));
  
          return HttpClient.post(API_ENDPOINTS.CHANGE_PASSWORD, fd);
        },
      };
      sectors ={
        getSectors: () =>
          HttpClient.get(API_ENDPOINTS.SECTORS),
      };
      country ={
        getCountry: () => 
        HttpClient.get(API_ENDPOINTS.COUNTRIES),
        getState: (id) => HttpClient.get(API_ENDPOINTS.STATES_COUNTRY(id)),
        getCity: (id) => HttpClient.get(API_ENDPOINTS.CITY_COUNTRY(id)),
      }
      web ={
        getSteps: () =>
          HttpClient.get(API_ENDPOINTS.WEB_STEPS)
      }
      
  }
export const client = new Client();
