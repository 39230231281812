import React from 'react'
import './Profile.css'
import profile from '../../../../Images/profile/img.png'
import { useSelector } from 'react-redux'
export default function ProfileLeft() {
    const lang = useSelector(state=>state.language.language)
  return (
    <div className='left'>
        <div className='profile'>
            <div className="user">
                <div className='picture'>
                    <img src={profile} alt="" />
                </div>
                <div className='name'>
                    <h4>{lang==='en'?'Fatma Ahmed':'فاطمة احمد'}</h4>
                    <h5>{lang==='en'?'Product Designer':'مصمم المنتج'}</h5>
                </div>
                <div className='button'>
                    <button className='btn-view-profile'>{lang==='en'?'View profile':'عرض الصفحة الشخصية'}</button>
                </div>
            </div>
            <div className='menu-link'>
                <ul>
                    <li>{lang==='en'?'Home':'الرئيسية'}</li>
                    <li>{lang==='en'?'How it works':'كيفية الانضمام'}</li>
                    {/* <li>Pricing</li> */}
                    <li>{lang==='en'?'Contact us':'تواصل معنا'}</li>
                </ul>
            </div>
        </div>
        <div className='connect'>
            <div className='info'>
                <ul>
                <li><i className='fa fa-phone'></i> {lang==='en'?'01288804887':'01288804887'}</li>
                        <li><i className='fa fa-envelope'></i> {lang==='en'?'vzadicompany@gmail.com':'vzadicompany@gmail.com'}</li>
                        <li><i className='fa fa-location-dot'></i> {lang==='en'?'Giza, 6th of October City, West Sumed, 8th Neighborhood':'الجيزة، مدينة السادس من اكتوبر، غرب سوميد المجاورة الثامنة '}</li>
                </ul>
            </div>
            <div className='media'>
                <ul>
                    <li><i className='fa-brands fa-linkedin'></i></li>
                    <li><i className='fa-brands fa-facebook'></i></li>
                    <li><i className='fa-brands fa-instagram'></i></li>
                    <li><i className='fa-brands fa-x-twitter'></i></li>
                    <li><i className='fa-brands fa-tiktok'></i></li>
                    <li><i className='fa-brands fa-youtube'></i></li>
                </ul>
            </div>
        </div>
    </div>
  )
}
