import React, { createContext, useContext, useState } from 'react';
import { checkHasAuthToken, delete_cookie, get_cookie, save_token_in_cookie } from '../../Client/token';
import { useMe } from '../../data/user';

const AuthContext = createContext(undefined);

const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthorized, setIsAuthorized] = useState(get_cookie('user'));
  const contextValue = {
    user,
    isAuthorized,
    authorize(data) {
      setUser(data)
      setIsAuthorized(true);
      save_token_in_cookie(`Bearer ${data.token}`);
    },
    unauthorize() {
      setUser(null)
      setIsAuthorized(false);
      delete_cookie("user", "/");
    },
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useUserContext must be used within a UserContextProvider');
  }
  return context;
};

export { UserContextProvider, useAuth };
