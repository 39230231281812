import {  RouterProvider, createBrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import MainLayout from './Layout/MainLayout';
import HomePage from './Pages/HomePage';
import LoginPage from './Pages/Auth/LoginPage';
import RegisterPage from './Pages/Auth/RegisterPage';
import HowItWorkPage from './Pages/HowItWorkPage';
import ContactUsPage from './Pages/ContactUsPage';
import PricingPage from './Pages/PricingPage';
import VerificationCodePage from './Pages/Auth/VerificationCodePage';
import UploadDocumentPage from './Pages/Auth/UploadDocumentPage';
import VerifyOtpPage from './Pages/Auth/VerifyOtpPage';
import ForgetPasswordPage from './Pages/Auth/ForgetPasswordPage';
import NewPasswordPage from './Pages/Auth/NewPasswordPage';
import RecoveryAccountPage from './Pages/Auth/RecoveryAccountPage';
import ServiceProviderFeaturesPage from './Pages/ServiceProviderFeaturesPage';
import MomiesFeaturesPage from './Pages/MomiesFeaturesPage';
import ErrorPage from './Pages/ErrorPage';
import ProfilePage from './Pages/ProfilePage';
import CompaniesForumPage from './Pages/CompaniesForumPage';

function App() {

  let route = createBrowserRouter([
      {path:"/", element: <MainLayout/>,children:[
        {index:true , element: <HomePage/>},
        // start Authentication
        {path:'/login', element: <LoginPage/>},
        {path:'/register', element: <RegisterPage/>},
        {path:'/verify-code/:id/:from', element: <VerificationCodePage/>},
        {path:'/upload-document/:id', element: <UploadDocumentPage/>},
        {path:'/forget-password', element: <ForgetPasswordPage/>},
        {path:'/new-password/:id', element: <NewPasswordPage/>},
        {path:'/recovery-account', element: <RecoveryAccountPage/>},
        {path:'/verify-otp', element: <VerifyOtpPage/>},
        {path:'/profile/:id', element: <ProfilePage/>},
        // end Authentication 
        {path:'/how-it-work', element: <HowItWorkPage/>},
        {path:'/contact-us', element: <ContactUsPage/>},
        {path:'/pricing', element: <PricingPage/>},
        {path:'/momies-features', element: <MomiesFeaturesPage/>},
        {path:'/service-provider-features', element: <ServiceProviderFeaturesPage/>},
        {path:'/companies-forum', element: <CompaniesForumPage/>},
        // {path:'*', element: <ErrorPage/>},
      ]},
      ]
  )
  return <>
    <RouterProvider router={route}/>
  </>
}

export default App;
