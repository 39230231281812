import React, { useEffect, useRef, useState } from 'react';
import logo from '../../../../Images/logo.svg';
import logoAr from '../../../../Images/logo-ar.svg';
import FilterRight from '../../../../Images/FilterRight.svg';
import './Navbar.css';
import { useDispatch, useSelector } from 'react-redux';
import { changeLanguage } from '../../../../Redux/Slice/languageSlice';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useCustomMutation from '../../../../hooks/use-custom-mutation';
import { client } from '../../../../Client/apis-method';
import { API_ENDPOINTS } from '../../../../Client/end-points';
import { useAuth } from '../../../Auth/context';
import { useMe } from '../../../../data/user';
import {  get_cookie } from '../../../../Client/token';

export default function Navbar() {
    let lang = useSelector(state => state.language.language);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [showNavLinks, setShowNavLinks] = useState(false);
    const { unauthorize , user } = useAuth();  
    const {me} = useMe() 
    const toggleNavLinks = () => {
        setShowNavLinks(!showNavLinks);
    };
    const isActiveLink = (path) => {
        return location.pathname === path;
      };
    
    

    const { mutate: logout } = useCustomMutation({
        mutationFn: client.auth.logout,
        queryToInvalidate: { queryKey: [API_ENDPOINTS.GET_PROVIDER] },
        onSuccess: async() => {
            unauthorize();
            navigate('/login')
        },
      });
      const navbarRef = useRef(null);
      const handleClickOutside = (event) => {
        if (navbarRef.current && !navbarRef.current.contains(event.target)) {
            setShowNavLinks(false);
        }
    };
    
    useEffect(() => {
        const handleMouseDown = (event) => {
            document.addEventListener('mouseup', handleClickOutside);
        };
    
        document.addEventListener('mousedown', handleMouseDown);
    
        return () => {
            document.removeEventListener('mousedown', handleMouseDown);
            document.removeEventListener('mouseup', handleClickOutside);
        };
    }, []);
    

    return (
        <div className='nav' ref={navbarRef}>
            <div className='nav-content'>
                <div className='nav-logo'>
                    <Link to='/'>
                        <img src={lang === 'en' ? logo : logoAr} alt="" />
                    </Link>
                    <div className={`toggle-btn ${showNavLinks?'active':''}`} onClick={toggleNavLinks}>
                    {showNavLinks ? 
                         <i className='fa fa-times fa-xl' style={{padding:"15px"}}></i>:
                        <img src={FilterRight} style={{width:"80%"}} alt="" />
                    }
                    </div>
                </div>
                <div className={`${showNavLinks? 'active' : ''} nav-links`}>
                    <ul>
                        <div className='link'>
                            <li className='li-link'><span className={`li-link ${isActiveLink('/') ? 'active' : ''}`}><Link to='/'>{lang === 'en' ? 'Home' : 'الرئيسية'}</Link></span></li>
                        </div>
                        <div className='link'>
                            <li className='li-link'><span className={`li-link ${isActiveLink('/how-it-work') ? 'active' : ''}`}><Link to='/how-it-work'>{lang === 'en' ? 'How it works' : 'كيفية الانضمام'}</Link></span></li>
                        </div>
                        {(get_cookie('user')? (user || me): user) &&
                        <div className='link'>
                            <li className='li-link'><span className={`li-link ${isActiveLink('/companies-forum') ? 'active' : ''}`}><Link to='/companies-forum'>{lang === 'en' ? 'Companies forum' : 'ملتقي الشركات'}</Link></span></li>
                        </div>}
                        {/* <div className='link'>
                            <li className='li-link'>
                                <span><Link to='/'>{lang === 'en' ? 'How it works' : 'كيفية الانضمام'} <i className="fa-solid fa-chevron-down"></i></Link>
                                    
                                </span>
                                <div className='dropdown-menu'>
                                    <ul>
                                        <li className='dropdown-links'>
                                            <div className={`li-link ${isActiveLink('/how-it-work') ? 'active' : ''}`}>
                                                <Link to='/how-it-work'>
                                                    <span>
                                                        <i className='fa fa-person px-1'></i>
                                                    </span>
                                                    {lang === 'en' ? 'How it work' : 'كيفية الانضمام لنا'}
                                                </Link>
                                            </div>
                                        </li>
                                    </ul>
                                    <div>
                                        <img src={logo} alt="" />
                                    </div>
                                </div>
                            </li>
                        </div> */}
                        {/* <div className='link'>
                            <li className='li-link'><span className={`li-link ${isActiveLink('/pricing') ? 'active' : ''}`}><Link to='/pricing'>{lang === 'en' ? 'Pricing' : 'الاسعار'}</Link></span></li>
                        </div> */}
                        <div className='link'>
                            <li className='li-link'><span className={`li-link ${isActiveLink('/contact-us') ? 'active' : ''}`}><Link to='/contact-us'>{lang === 'en' ? 'Contact us' : 'تواصل معنا '}</Link></span></li>
                        </div>
                    </ul>
                </div>
                <div className={`${showNavLinks ? 'active' : ''} nav-links nav-auth`}>
                    <div className='nav-auth-content'>
                        <div onClick={() => dispatch(changeLanguage(`${lang === 'en' ? 'ar' : 'en'}`))}>
                            {lang === 'en' ? 'عربي' : 'English'}
                        </div>
                        <ul>

                        {(get_cookie('user')? (user || me): user)?
                        <div className='link link-profile'>
                            <li className='li-profile'>
                                <span>
                                    <Link to='/' className='btn-outline-main'>
                                        {lang === 'en' ? 'My account' : 'حسابي'}
                                        <i className="fa-solid fa-chevron-down"></i>
                                    </Link>                            
                                </span>
                                <div className='dropdown-menu li-profile'>
                                    <ul>
                                        <li className='dropdown-links'>
                                            <div>
                                                <Link to={`/profile/${me?._id}`} >
                                                    {lang === 'en' ? 'Profile' : 'حسابي'}
                                                </Link>
                                            </div>
                                        </li>
                                        <li className='dropdown-links'>
                                            <div>
                                                <a target='_blank' href='https://providers.momies.net/cpanel-manager/provider/dashboard' >
                                                    {lang === 'en' ? 'Dashboard' : 'لوحة التحكم'}
                                                </a>
                                            </div>
                                        </li>
                                        <li className='dropdown-links'>
                                            <div>
                                                <div className='logout' onClick={logout} >
                                                    {lang === 'en' ? 'Logout' : 'تسجيل الخروج'}
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </div>
                        :
                        <div className='link link-profile '>
                            <li className='li-profile'>
                                <span>
                                <a className='btn-outline-main'>
                                    {lang === 'en' ? 'Sign now' : 'سجل الان'}
                                </a>                     
                                </span>
                                <div className='dropdown-menu li-profile'>
                                    <ul>
                                        <li className='dropdown-links'>
                                            <div>
                                                <span className={`li-link ${isActiveLink('/register') ? 'active' : ''}`}>
                                                    <Link to='/register'>
                                                        {lang === 'en' ? 'Register' : 'انشاء حساب'}
                                                    </Link>
                                                </span>
                                            </div>
                                        </li>
                                        <li className='dropdown-links'>
                                            <div>
                                            <span className={`li-link ${isActiveLink('/login') ? 'active' : ''}`}>
                                                    <Link to='/login'>
                                                        {lang === 'en' ? 'Login' : 'تسجيل الدخول'}
                                                    </Link>
                                                </span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </div>

                        }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}
