import React from 'react'
import { useSectors } from '../../../../data/vistor';
import { useSelector } from 'react-redux';

const SelectCountry = ({ name, label, value, options, ...props }) => {
  const lang = useSelector((state) => state.language.language);
  return (
    <div className='input-group'>
      <label type={props?.type} htmlFor={name}>
        {label}
      </label>
      <select className={lang==='en'?'':'ar'} id={props.name} name='country' {...props}>
        <option value=''>{label}</option>
        {options?.map((country, index) => (
          <option key={index} value={country.id}>
            {lang==='en'? country.name.english : country.name.arabic}
          </option>
          
        ))}
      </select>
    </div>
  );
};
const Select = ({ name,
    label,
    value,
    options,...props})=> {
      const lang = useSelector((state) => state.language.language);
      return (
    <div className='input-group'>
        <label type={props?.type} htmlFor={name}>{label}</label>
        <select className={lang==='en'?'':'ar'} id={props.name} name={name} {...props}>
            <option value=''>{label}</option>
            {options?.map((d,index)=>(
                <option key={index} value={d.value}>{d.label}</option>
            ))}
        </select>
    </div>
  )
}
export const CountrySelector = (props) => {
    return <SelectCountry
    options={props.options}
    label='Country'
    onChange={props.onChange}
    {...props}
  />;
};

export const SectorSelector = (props) => {
    const { data } = useSectors();
  
    const options = data?.map((p) => ({
      value: p?._id,
      label: p?.name.english,
    }));
  
    return <Select options={options} label={'Sectors'} {...props} />;
};
