import React from 'react'
import ServiceProviderCard from './ServiceProviderCard'

export default function Provider() {
  return (
    <div className="section-content feature">
            <section className='features'>
                    <ServiceProviderCard/>
                    {/* <ServiceProviderCard/>
                    <ServiceProviderCard/>
                    <ServiceProviderCard/>
                    <ServiceProviderCard/>
                    <ServiceProviderCard/> */}
            </section>
        </div>
  )
}
