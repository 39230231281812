import { configureStore } from "@reduxjs/toolkit";
import languageSlice from "./Slice/languageSlice";
import userDataSlice from "./Slice/userDataSlice";

 const store = configureStore({
    reducer:{
        language: languageSlice,
        userData: userDataSlice,
    },
})
export default store;