import React from 'react'
import Navbar from '../Components/Layout/ui/Navbar/Navbar'
import { Outlet } from 'react-router-dom'
import Footer from '../Components/Layout/ui/Footer/Footer'
import { useSelector } from 'react-redux'

export default function MainLayout() {
  let lang = useSelector(state=>state.language.language)

  return (
    <div dir={lang==='en'?'ltr':'rtl'}>
        <Navbar/>
        <Outlet/>
        <Footer/>
    </div>
  )
}