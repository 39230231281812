import { createSlice } from "@reduxjs/toolkit";

const languageSlice = createSlice({
    name: 'Language',
    initialState: {
        language: `${(localStorage.getItem('language'))? `${(localStorage.getItem('language'))}`:'ar'}`,
    },
    reducers:{
        changeLanguage: (state,action)=>{
            state.language = action.payload
            localStorage.setItem('language', action.payload)
        }
    }
})
export const {changeLanguage} = languageSlice.actions
export default languageSlice.reducer;