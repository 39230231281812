import React, { useEffect } from 'react'
import HowItWork from '../Components/Layout/ui/HowItWork/HowItWork'
import { useSelector } from 'react-redux'
import FrequentlyQuestion from '../Components/Layout/ui/HowItWork/FrequentlyQuestion'

export default function HowItWorkPage() {
  const lang = useSelector(state=>state.language.language)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='section'>
        <div className='section-header'>
          {lang==='en'?
            <h1>How it <span>works</span>?</h1>:<h1>كيفية <span>الانضمام</span> لنا؟</h1>}
            <h3>{lang==='en'?'Lorem ipsum dolor sit amet consectetur. Tristique turpis dui eget ipsum':'منصة واحدة لتلبية احتياجات الأمهات بشكل شاملمنصة واحدة لتلبية احتياجات الأمهات '}</h3>
        </div>
        <HowItWork/>
        <FrequentlyQuestion/>
    </div>
  )
}
