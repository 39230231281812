import React, { useEffect } from 'react'
import Input from '../../Components/Layout/ui/SystemLogin/Input'
import SubmitButton from '../../Components/Layout/ui/SystemLogin/SubmitButton'
import { useSelector } from 'react-redux'
import { useNavigate, useParams  } from 'react-router-dom'
import { useFormData } from '../../hooks/use-form-data-hook';
import useCustomMutation from '../../hooks/use-custom-mutation';
import { client } from '../../Client/apis-method';
import { AppValidators } from '../../Client/validation'
import { toast } from 'react-toastify'
import ResendCode from '../../Components/Layout/ui/ResendCode.jsx'

export default function VerificationCodePage() {
    const lang = useSelector(state=>state.language.language)
    const navigate = useNavigate();
    const params = useParams();
    const verifyCode= useFormData({
        verificationCode: '',
        type:params.from === 'recovery'?'reset':''
      });
      
      const verifyCodeMutation = useCustomMutation({
        mutationFn: client.auth.verifyCode,
        onSuccess: (data) => {
          if(data.status === "success"){
            if(params.from === 'register'){
              navigate(`/upload-document/${params.id}`)
            }else if(params.from === 'forget'){
              navigate(`/new-password/${params.id}`)
            }
          }
        },
       
      });
      const verifyRecoverAccountMutation = useCustomMutation({
        mutationFn: client.auth.verify_recover_account,
        onSuccess: (data) => {
          if(data.status === "success"){
            navigate(`/new-password/${data.userId}`)
          }
        },
      });
      
      const VerifyCodeSubmit = (data) => {
        try {
          AppValidators.verificationCode(data.verificationCode)
          params.from === 'recovery'?
          verifyRecoverAccountMutation.mutate(data)         
          :
          verifyCodeMutation.mutate({id:params?.id,data})
        } catch (error) {
          toast.error(error.message)        
        }
      };

      
      useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
         <div className='form'>
          <div className='form-package'>
            <h4>{lang==='en'?'Verify Code':'تحقيق الرمز'}</h4>
            <form className='form-group' onSubmit={verifyCode.handleSubmit(VerifyCodeSubmit)}>
                <div className='inputs-group'>
                  <div className='remember-me'>
                    <Input {...verifyCode.register('verificationCode')} maxLength={6} label={lang==='en'?'Verification Code':'رمز التحقق'} name='verificationCode' placeholder={lang==='en'?'Enter you verificationCode':'ادخل رمز التحقق'} type='text'/>
                    <ResendCode />
                  </div>
                </div>
                <SubmitButton name={lang==='en'?'Verify':'تحقق'} children={lang==='en'?'Submit':'تسجيل'} isLoading={verifyCodeMutation.isPending}/>
              </form>
            </div>
        </div>
  )
}
