import React, { useEffect } from 'react'
import Input from '../../Components/Layout/ui/SystemLogin/Input'
import SubmitButton from '../../Components/Layout/ui/SystemLogin/SubmitButton'
import { useSelector } from 'react-redux'
import {  useNavigate, useParams } from 'react-router-dom'
import { useFormData } from '../../hooks/use-form-data-hook';
import useCustomMutation from '../../hooks/use-custom-mutation';
import { client } from '../../Client/apis-method';
import { AppValidators } from '../../Client/validation'
import {  SectorSelector } from '../../Components/Layout/ui/SystemLogin/Select'
import { toast } from 'react-toastify'

export default function UploadDocumentPage() {
    const lang = useSelector(state=>state.language.language)
    const navigate = useNavigate();
    const param = useParams()
    const uploadDocument = useFormData({
        departMentName: '',
        sector: '',
        address:'',
        institutionsLink:''
      });
      const uploadDocumentMutation = useCustomMutation({
        mutationFn: client.auth.uploadDocument,
        onSuccess: (data) => {
          if(data.status === "success"){
            navigate('/login');
          }
        },
      });
      const uploadDocumentSubmit = (data) => {
        try {
          if(data.institutionsLink){
            AppValidators.required(data.departMentName,'Organization Name')
            AppValidators.required(data.sector,'Sector')
            AppValidators.required(data.address,'Address');
            AppValidators.urlLink(data.institutionsLink)
            uploadDocumentMutation.mutate({id:param?.id,...data});
          }else{
            AppValidators.required(data.departMentName,'Organization Name')
            AppValidators.required(data.sector,'Sector')
            AppValidators.required(data.address,'Address');
            uploadDocumentMutation.mutate({id:param?.id,data:{
              departMentName:data.departMentName,
              sector:data.sector,
              address:data.address,
            }});
          }
        } catch (error) {
          toast.error(error.message);          
        }
      };
      useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    
  return (
    <div className='form'>
    <div className='form-package'>
      
      <h4>{lang==='en'?'Profile Details':'تفاصيل الحساب'}</h4>
      <form className='form-group' onSubmit={uploadDocument.handleSubmit(uploadDocumentSubmit)}>
            <div className='inputs-group'>
              <Input {...uploadDocument.register('departMentName')} label={lang==='en'?'Organization Name':'اسم المؤسسه'} name='departMentName' placeholder={lang==='en'?'Enter Orgnization Name':'ادخل اسم المؤسسه'} type='text'/>
              <SectorSelector {...uploadDocument.register('sector')}/>          
              <Input {...uploadDocument.register('address')} label={lang==='en'?'Address':'العنوان'} name='address' placeholder={lang==='en'?'Enter you address':'ادخل العنوان'} type='text'/>
              <Input {...uploadDocument.register('institutionsLink')} label={lang==='en'?'Link':'كلمة المرور'} name='institutionsLink' placeholder={lang==='en'?'Link (optional)':'الرابط اختياري'} type='text'/>
              </div>
            <SubmitButton name={lang==='en'?'Register':'انشاء حساب'} children={lang==='en'?'Submit':'تسجيل'} isLoading={uploadDocumentMutation.isPending}/>
          </form>
      </div>
  </div>
  )
}
