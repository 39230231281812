import React, { useEffect, useState } from 'react'
import Input from '../../Components/Layout/ui/SystemLogin/Input'
import SubmitButton from '../../Components/Layout/ui/SystemLogin/SubmitButton'
import { useSelector } from 'react-redux'
import {  useNavigate, useParams } from 'react-router-dom'
import useCustomMutation from '../../hooks/use-custom-mutation'
import { client } from '../../Client/apis-method'
import { AppValidators } from '../../Client/validation'
import { useFormData } from '../../hooks/use-form-data-hook'
import { toast } from 'react-toastify'

export default function NewPasswordPage() {
  const [error, setError] = useState(null)
  const navigate = useNavigate()
  const params = useParams()
  const newPassword= useFormData({
    password:'',
    confirmPassword:''
  });
  const newPasswordMutation = useCustomMutation({
    mutationFn: client.auth.reset_password,
    onSuccess: (data) => {
      if(data){
        navigate('/login')
      }
      
    },
  });
  const newPasswordSubmit = (data) => {
    try {
      if(newPassword.data.password !== newPassword.data.confirmPassword ){
        return setError('Password and Confirm Password are not the same')
      }
      AppValidators.password(data.password);
      newPasswordMutation.mutate({
        password:newPassword.data.password,
        id:params.id
      });
    } catch (error) {
      toast.error(error.message)
    }
  };
  const lang = useSelector(state=>state.language.language);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='form'>
      <div className='form-package'>
        <form  className='form-group' onSubmit={newPassword.handleSubmit(newPasswordSubmit)}>
          <div className='inputs-group'>
            <Input {...newPassword.register('password')} label={lang==='en'?'New Password':'كلمة المرور'} name='password' placeholder={lang==='en'?'Enter new password':'ادخل كلمة المرور'} type='password'/>
            <Input {...newPassword.register('confirmPassword')}  label={lang==='en'?'Confirm Password':'كلمة المرور'} name='confirmPassword' placeholder={lang==='en'?'Enter same new password':'ادخل كلمة المرور اخري'} type='password'/>
            {error && <div className='form-error'>{error}</div>}
          </div>
          <SubmitButton name={lang==='en'?'Login':'تسجيل دخول'} />
        </form>
      </div>
    </div>
  )
}
