import React from "react";
import './ContactUs.css'
import ContactUs from '../../../../Images/ContactUs/contact-us.png';
import Input from "../SystemLogin/Input";
import SubmitButton from "../SystemLogin/SubmitButton";
import { useSelector } from "react-redux";
import TextArea from "../SystemLogin/TextArea";
export default function ContactUS(){
    const lang = useSelector(state=> state.language.language)

    return(
        <div className="section-content">
            <div className='connect'>
                <div className="title">
                    <h3>{lang==='en'?'Contact Information':'معلومات التواصل'}</h3>
                    <p>{lang==='en'?'Say something to start a live chat!':'ارسل رسالة لبدئ الدردشة الحيّة '}</p>
                </div>
                <div className='info'>
                    <ul>
                        <li><i className='fa fa-phone'></i> {lang==='en'?'01288804887':'01288804887'}</li>
                        <li><i className='fa fa-envelope'></i> {lang==='en'?'vzadicompany@gmail.com':'vzadicompany@gmail.com'}</li>
                        <li><i className='fa fa-location-dot'></i> {lang==='en'?'Giza, 6th of October City, West Sumed, 8th Neighborhood':'الجيزة، مدينة السادس من اكتوبر، غرب سوميد المجاورة الثامنة '}</li>
                    </ul>
                </div>
                <div className='media'>
                    <ul>
                        <li><i className='fa-brands fa-linkedin'></i></li>
                        <li><i className='fa-brands fa-facebook'></i></li>
                        <li><i className='fa-brands fa-instagram'></i></li>
                        <li><i className='fa-brands fa-x-twitter'></i></li>
                        <li><i className='fa-brands fa-tiktok'></i></li>
                        <li><i className='fa-brands fa-youtube'></i></li>
                    </ul>
                </div>
            </div>
            <div className="img">
                <img src={ContactUs} alt="" />
                <div className="btn">
                    <div className="btn-main">
                        <a href="#">{lang==='en'?'Chat with us':'دردش معنا'}</a>
                    </div>
                </div>
            </div>
        </div>
    )

}