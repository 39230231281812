import React from 'react'
import Slider from "react-slick";
import JobCard from './JobCard';
import { useSelector } from 'react-redux';

export default function ProfileMiddle() {
    const lang = useSelector(state=>state.language.language)
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows:false,
      };
  return (
    <div className='middle'>
        <div className='header'>
            <ul>
                <li>{lang==='en'?'Categories':'فئات'}</li>
                <li>{lang==='en'?'Products':'منتجات'}</li>
                <li>{lang==='en'?'Offers':'عروض'}</li>
                <li className='active'>{lang==='en'?'Jobs':'وظائف'}</li>     
            </ul>
        </div>
        {/* <div className='discover-slider'>
        <Slider {...settings}>
            <div className=''>

            </div>
        </Slider>
        </div> */}
        <div className='jobs'>
            <JobCard/>
            <JobCard/>
            <JobCard/>
            <JobCard/>
            <JobCard/>
            <JobCard/>
            <JobCard/>
            <JobCard/>
        </div>
    </div>
  )
}
