import React, { useEffect } from 'react'
import Input from '../../Components/Layout/ui/SystemLogin/Input'
import shapeSparkle from '../../Images/utilis/Elements-organic-shape-abstract-sparkle-dash.svg'
import SubmitButton from '../../Components/Layout/ui/SystemLogin/SubmitButton'
import { useSelector } from 'react-redux'
import { Link, useNavigate  } from 'react-router-dom'
import useCustomMutation from '../../hooks/use-custom-mutation'
import { client } from '../../Client/apis-method'
import { AppValidators } from '../../Client/validation'
import { useFormData } from '../../hooks/use-form-data-hook'
import { toast } from 'react-toastify'

export default function ForgetPasswordPage() {
  const navigate = useNavigate()
  const forgetPassword= useFormData({
    email:'',
  });
  const forgetPasswordMutation = useCustomMutation({
    mutationFn: client.auth.forget_password,
    onSuccess: (data) => {
      if(data.status === "success"){
        navigate(`/verify-code/${data.data}/forget`)
      }
    },
  });
  const ForgetPasswordSubmit = (data) => {
    try {
      AppValidators.email(data.email);
      forgetPasswordMutation.mutate(data);
    } catch (error) {
      toast.error(error.message)
    }
  };
  const lang = useSelector(state=>state.language.language);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='form'>
      <div className='form-package'>
        <div className='group-buttons'>
          <div>
            <button className='selected'>{lang==='en'?'Login':'تسجيل دخول'}</button>
            <button className='not-selected'><Link to='/register'>{lang==='en'?'Register':'انشاء حساب'}</Link></button>
          </div>
            <img className='shape-sparkle' src={shapeSparkle} alt="" />
        </div>
        <h5>{lang==='en'?'Lorem Ipsum is simply dummy text of the printing and typesetting industry.':'نصة واحدة لتلبية احتياجات الأمهات بشكل شامل منصة واحدة لتلبية احتياجات الأمهات بشكل شامل منصة بشكل'}</h5>
        <form  className='form-group' onSubmit={forgetPassword.handleSubmit(ForgetPasswordSubmit)}>
          <div className='inputs-group'>
          <Input {...forgetPassword.register('email')} label={lang==='en'?'Email address':'بريدك الالكتروني'} name='email' placeholder={lang==='en'?'Enter your Email Address':'ادخل بريدك الالكتروني'} type='email'/>
            
          </div>
          <SubmitButton name={lang==='en'?'Login':'تسجيل دخول'} />
        </form>
      </div>
    </div>
  )
}
