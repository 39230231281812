import * as yup from 'yup';

export class AppValidators {
    static firstName(firstName) {
        return yup
          .string().min(3, 'First Name must be at least 3 characters')
          .max(20 ,'Last Name must be at least 20 characters')
          .required('First Name is required')
          .validateSync(firstName);
      }
      
      static lastName(lastName) {
        return yup
          .string().min(3, 'Last Name must be at least 3 characters')
          .max(20,'Last Name must be at least 20 characters')
          .required('Last Name is required')
          .validateSync(lastName);
      }
      

  static email(email) {
    return yup
      .string()
      .required('Email is required')
      .email('Email is not valid')
      .matches(/^(?:[a-zA-Z0-9._%+-]+@(?:yahoo|gmail|hotmail|outlook|live)\.(?:com|co\.uk|net|org|edu|gov|mil|biz|info|mobi|name|aero|asia|jobs|museum)\b)$/,'Email pattern not allowed')
      .validateSync(email);
  }


  static password(password) {
    return yup
      .string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters long')
      .matches(/[0-9]/, 'Password must contain at least one number')
      .matches(/[A-Z]/, 'Password must contain at least one upper case letter')
      .matches(/[!@#$%^&*()_+\-=\[\]{};:"|,.<>\/?'"']/, 'Password must contain at least one special character %#@$')
      .validateSync(password);
  }

  static required(value,filed) {
    return yup.string().required(`${filed} is Required`).validateSync(value);
  }

  static match(password, confirmPassword) {
    return yup
      .string()
      .required('confirm password required')
      .oneOf([yup.ref(password)], 'Passwords do not match')
      .validateSync(confirmPassword);
  }

  static numbersExactLength(value, length) {
    return yup
      .string()
      .required('Required')
      .length(length, `This Field's Length Should be${length}`)
      .validateSync(value);
  }
  static verificationCode(verificationCode) {
    return yup
      .string()
      .required('Verification Code is Required')
      .validateSync(verificationCode);
  }

  static phoneNumber(value) {
    return yup
      .string()
      .matches(/^(00201|\+201|01)[0-2,5]{1}[0-9]{8}$/,'Must be egyptian number')
      .required('phone number is required')
      .validateSync(value);
  }

  static identical(value, other) {
    return yup.string().required('Identical is required').oneOf([other], 'Must be identical').validateSync(value);
  }

  static otp(value) {
    return yup.string().required('Please Enter OTP Code').length(6, 'Please Enter 6 numbers').validateSync(value);
  }

  static price(value) {
    return yup.string().required('Price is required').matches(/^[+-]?\d+$/, 'Please Enter price valid').validateSync(value);
  }

  static urlLink(value) {
    return yup
      .string()
      .required('url is required')
      .test('url', 'Please Enter url valid', (val) => val.startsWith('https://') && val.endsWith('.com'))
      .validateSync(value);
  }
}