import { useQuery } from '@tanstack/react-query';
import { API_ENDPOINTS } from '../Client/end-points';
import { useAuth } from '../Components/Auth/context';
import { client } from './../Client/apis-method';

export const useMe = () => {
  const { isAuthorized  } = useAuth();
  const { isPending, data, isError, error } = useQuery({
    queryKey: isAuthorized && [API_ENDPOINTS.GET_PROVIDER],
    queryFn:  isAuthorized && client.auth.getProvider,
  });
  return {
    isPending,
    isError,
    error,
    isAuthorized,
    me: data?.data
  };
};
