import { useQuery } from '@tanstack/react-query';
import { API_ENDPOINTS } from '../Client/end-points';
import { client } from '../Client/apis-method';

export const useSectors = () => {
  const { isPending, data, error, isError } = useQuery({
    queryKey: [API_ENDPOINTS.SECTORS],
    queryFn: client.sectors.getSectors,
  });    
  return {
    isPending,
    isError,
    error:error,
    data: data?.data,
  };
};

export const useCountry = () => {
  const { isPending, data, error, isError } = useQuery({
    queryKey: [API_ENDPOINTS.COUNTRIES],
    queryFn: client.country.getCountry,
  });    
  return {
    isPending,
    isError,
    error:error,
    data: data,
  };
};
export const useState = (id) => {
  const { isPending, data, error, isError } = useQuery({
    queryKey: [API_ENDPOINTS.STATES_COUNTRY],
    queryFn: client.country.getState(id),
  });    
  return {
    isPending,
    isError,
    error:error,
    data: data,
  };
};
export const useCity = (id) => {
  const { isPending, data, error, isError } = useQuery({
    queryKey: [API_ENDPOINTS.CITY_COUNTRY],
    queryFn: client.country.getCity(id),
  });    
  return {
    isPending,
    isError,
    error:error,
    data: data,
  };
};