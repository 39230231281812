import React from 'react'
import icon from '../../../../Images/features/service.svg';
import { useSelector } from 'react-redux';
export default function ServiceProviderCard() {
  const lang = useSelector(state=>state.language.language)

  return (
    // <div className='provider-card'>
    //     <div className='icon'><img src={icon} alt="" /></div>
    //     <h3>Customer engagement</h3>
    //     <ul>
    //         <li>Chat widget ›</li>
    //         <li>Chat widget ›</li>
    //         <li>Chat widget ›</li>
    //         <li>Chat widget ›</li>
    //         <li>Chat widget ›</li>
    //         <li>Chat widget ›</li>
    //         <li>Chat widget ›</li>
    //         <li>Chat widget ›</li>
    //         <li>Chat widget ›</li>
    //     </ul>
    // </div>
    <>
                <div className='provider-card'>
              <div className='icon'><img src={icon} alt="" /></div>
              <h3>{lang==='en'?'':'التعليمي'}</h3>
              <ul>
                  <li>{lang==='en'?' ›':'مدارس انترناشونال ›'}</li>
                  <li>{lang==='en'?' ›':'مدارس عربي ›'}</li>
                  <li>{lang==='en'?' ›':'مدارس عربي ›'}</li>
                  <li>{lang==='en'?' ›':'مدارس عربي ›'}</li>
                  <li>{lang==='en'?' ›':'حضانات ›'}</li>
                  <li>{lang==='en'?' ›':'مراكز تعليميه ›'}</li>
                  <li>{lang==='en'?' ›':'مكتبات ›'}</li>
                  <li>{lang==='en'?' ›':'خدمات طباعه وتصوير ›'}</li>
              </ul>
            </div>
            <div className='provider-card'>
                <div className='icon'><img src={icon} alt="" /></div>
                <h3>{lang==='en'?'':'المهني'}</h3>
                <ul>
                    <li>{lang==='en'?' ›':'خدمات صيانه المنزل والاجهزه ›'}</li>
                    <li>{lang==='en'?' ›':'تشطيبات و الديكور ›'}</li>
                    <li>{lang==='en'?' ›':'خدمات صيانة السيارات ›'}</li>
                </ul>
            </div>
            <div className='provider-card'>
                <div className='icon'><img src={icon} alt="" /></div>
                <h3>{lang==='en'?'':'مراكز تجميل'}</h3>
                <ul>
                    <li>{lang==='en'?' ›':'مراكز تجميل ›'}</li>
                    <li>{lang==='en'?' ›':'مراكز رياضيه ›'}</li>
                    <li>{lang==='en'?' ›':'مركز ايجار السيارات ›'}</li>
                    <li>{lang==='en'?' ›':'النوادي الرياضيه ›'}</li>
                    <li>{lang==='en'?' ›':'السنيمات ›'}</li>
                    <li>{lang==='en'?' ›':'قاعات الافراح ›'}</li>
                </ul>
            </div>
            <div className='provider-card'>
              <div className='icon'><img src={icon} alt="" /></div>
              <h3>{lang==='en'?'':'طبي'}</h3>
              <ul>
                  <li>{lang==='en'?' ›':'اسنان ›'}</li>
                  <li>{lang==='en'?' ›':'باطنه ›'}</li>
                  <li>{lang==='en'?' ›':'جلديه ›'}</li>
                  <li>{lang==='en'?' ›':'تجميل ›'}</li>
                  <li>{lang==='en'?' ›':'قلب ›'}</li>
                  <li>{lang==='en'?' ›':'مخ و اعصاب ›'}</li>
              </ul>
          </div>
    </>
  )
}
