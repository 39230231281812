export const API_ENDPOINTS ={
    CREATE_PROVIDER:'providers/api/providers/create-provider',
    VERIFY_CODE:(id)=>`providers/api/providers/verify-code/${id}`,
    FORGET_PASSWORD:'providers/api/providers/forgot-password',
    RECOVER_ACCOUNT:'providers/api/providers/recover-account',
    VERIFY_RECOVER_ACCOUNT:'providers/api/providers/verify-recover-account',
    GET_ONE_PROVIDER:'providers/api/providers/get-one-provider/:id',
    LOGIN:'providers/api/providers/login',
    GET_PROVIDER:'providers/api/providers/get-provider',
    LOGOUT:'providers/api/providers/logout',

    SECTORS:'api/sector/get-sectors',   
    CHANGE_EMAIL:'providers/api/providers/change-email',
    CHANGE_PASSWORD:'providers/api/providers/change-password',
    RESET_PASSWORD:'providers/api/providers/reset-password',
    SIGNUP_STEP_THREE:(id)=>`providers/api/providers/sginUp-step-three/${id}`,
    RESEND_CODE:(id)=>`providers/api/providers/resend-code/${id}`,
    SET_RECOVERY_DATA:'providers/api/providers/set-recovery-data',
    COUNTRIES:'api/countries',
    STATES_COUNTRY:(id)=>`api/countries/states/${id}`,
    CITY_COUNTRY:(id)=>`api/countries/cities/${id}`,

    //web 
    WEB_STEPS : 'providers/api/websteps/steps'
}