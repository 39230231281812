import React from 'react'
import './features.css'
import momiesFeature from '../../../../Images/features/momies-feature.png';
import MomiesCard from './MomiesCard';
import { useSelector } from 'react-redux';
export default function Momies() {
  const lang = useSelector(state=>state.language.language)
  return (
        <div className="section-content feature">
            <section className='desc'>
               <div className='description'>
                    <h1>{lang==='en'?'Momies advantages':'مميزات الأمهات'}</h1>
                    <p>{lang==='en'?'The Momies application is a team of supporters for every woman and mother who seeks to achieve the best for herself, her home, and her children. Therefore, we designed this application for you with all care to serve all aspects of life that you need to combine your personal life and interest in developing yourself.':'تطبيق ماميز هو تطبيق مصمم لكل أم دائما بتحاول توفر لبيتها وأولادها الافضل وعشان كده قررنا نساعدها توفق بين بيتها وشغلها وعنايتها بذاتها وتلاقي كل اللي تحتاجه.'}</p>
               </div>
               <div className='image'>
                <img src={momiesFeature} alt="" />
               </div>
            </section>
            <section className='features'>
                    <MomiesCard/>
                    
            </section>
        </div>
  )
}
