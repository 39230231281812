import React, { useEffect } from 'react'
import Input from '../../Components/Layout/ui/SystemLogin/Input'
import SubmitButton from '../../Components/Layout/ui/SystemLogin/SubmitButton'
import { useSelector } from 'react-redux'
import {  useNavigate  } from 'react-router-dom'
import useCustomMutation from '../../hooks/use-custom-mutation'
import { client } from '../../Client/apis-method'
import { useFormData } from '../../hooks/use-form-data-hook'
import { toast } from 'react-toastify'

export default function RecoveryAccountPage() {
    const navigate = useNavigate()
  const forgetPassword= useFormData({
    secEmail:'',
    pinCode:'',
  });
  const recoveryMutation = useCustomMutation({
    mutationFn: client.auth.recover_account,
    onSuccess: (data) => {
      if(data.status === "success"){
        navigate(`/verify-code/${data.data}/recovery`)
      }
    },
  });
  const RecoverySubmit = (data) => {
    try {
    //   AppValidators.email(data.secEmail);
      recoveryMutation.mutate(data);
    } catch (error) {
      toast.error(error.message)
    }
  };
  const lang = useSelector(state=>state.language.language);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='form'>
      <div className='form-package'>

        <h4>{lang==='en'?'Recovery Account':'استرجاع الحساب'}</h4>
        <form  className='form-group' onSubmit={forgetPassword.handleSubmit(RecoverySubmit)}>
          <div className='inputs-group'>
          <Input {...forgetPassword.register('secEmail')} label={lang==='en'?'Email address':'بريدك الالكتروني'} name='secEmail' placeholder={lang==='en'?'Enter your Email Address':'ادخل بريدك الالكتروني'} type='email'/>
          <Input {...forgetPassword.register('pinCode')} maxLength={6} label={lang==='en'?'PIN Code':'رمز التعريف الشخصي'} name='pinCode' placeholder={lang==='en'?'Enter your pin code':'ادخل رمز التعريف الشخصي'} type='text'/>
            
          </div>
          <SubmitButton name={lang==='en'?'Login':'تسجيل دخول'} />
        </form>
      </div>
    </div>
  )
}
