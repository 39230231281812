import axios from 'axios';
import {  get_cookie } from './token';
import { APIError } from './error';

const Axios = axios.create({
  baseURL: 'https://providers.momies.net/',
});

Axios.interceptors.request.use((config) => {
  if(get_cookie('user')){
    config.headers.Authorization = `${get_cookie('user')}`;
  }
  config.headers.apikey = `w`
  return config;
});

class HttpClient {
  static async get(url, params) {
    try {
      const response = await Axios.get(url, params);
      return response.data;
    } catch (error) {
      throw new APIError(error?.response?.data);
    }
  }

  static async post(url, data, config) {
    try {
      const response = await Axios.post(url, data, config);
      return response.data;
    } catch (error) {
      throw new APIError(error?.response?.data);
    }
  }

  static async put(url, data) {
    try {
      const response = await Axios.put(url, data);
      return response.data;
    } catch (error) {
      throw new APIError(error?.response?.data);
    }
  }

  static async patch(url, data, config) {
    try {
      const response = await Axios.patch(url, data, config);
      return response.data;
    } catch (error) {
      throw new APIError(error?.response?.data);
    }
  }

  static async delete(url, config) {
    try {
      const response = await Axios.delete(url, config);
      return response.data;
    } catch (error) {
      throw new APIError(error?.response?.data);
    }
  }
}

export { Axios, HttpClient };
