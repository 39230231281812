import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import CompaniesForum from '../Components/Layout/ui/CompaniesForum/CompaniesForum';
export default function CompaniesForumPage() {
    const lang = useSelector(state=>state.language.language)
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className='section main-layout CompaniesForum'>
        <div className='section-header'>
            <h1><span>{lang==='en'?'Companies Forum':'ملتقي الشركات'}</span></h1>
            <h3>{lang==='en'?'Lorem ipsum dolor sit amet consectetur. Tristique turpis dui eget ipsum':'منصة واحدة لتلبية احتياجات الأمهات بشكل شاملمنصة واحدة لتلبية احتياجات الأمهات '}</h3>
        </div>  
        <CompaniesForum/>
    </div>    
  )
}
