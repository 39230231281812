import React from 'react'
import { useSelector } from 'react-redux'
import './CompaniesForum.css'
import Input from '../SystemLogin/Input'
import SubmitButton from '../SystemLogin/SubmitButton'

export default function CompaniesForum() {
    const lang = useSelector(state=> state.language.language)

  return (
    <div className="section-content">
    <div className='connect'>
        <div className="title">
            <h3>{lang==='en'?'Company Information':'معلومات الشركة'}</h3>
            {/* <p>{lang==='en'?'Say something to start a live chat!':'ارسل رسالة لبدئ الدردشة الحيّة '}</p> */}
        </div>
        <form className='form-group'>
          <div className='inputs-group'>
            <div className='row'>
                <div className='col'>
                    <Input color='white' value='Vzadi' disabled label={lang==='en'?'Company name':'اسم الشركة'} type='text'/>
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <Input color='white' value='mos3addev@gmail.com' disabled label={lang==='en'?'Email':'البريد الاليكتروني'} name='email' type='text'/>
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <Input color='white' value='01104421062' disabled label={lang==='en'?'Phone number':'رقم التيليفون'} type='text'/>
                </div>
            </div>
            </div>
            <SubmitButton name={lang==='en'?'subscribe now':'اشترك الان'} />
        </form>
        
    </div>
</div>
  )
}
