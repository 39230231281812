import React from 'react'
import icon from '../../../../Images/features/trophy.svg'
import { useSelector } from 'react-redux'
export default function MomiesCard() {
  const lang= useSelector(state=>state.language.language)
  return (
    <>
      <div className='momies-card'>
          <div className='icon'><img src={icon} alt="" /></div>
          <h3>{lang==='en'?'Momies advantages':'مميزات الأمهات'} :</h3>
          <p>{lang==='en'?'The Momies application is a team of supporters for every woman and mother who seeks to achieve the best for herself, her home, and her children. Therefore, we designed this application for you with all care to serve all aspects of life that you need to combine your personal life and interest in developing yourself.':'تطبيق ماميز هو تطبيق مصمم لكل أم دائما بتحاول توفر لبيتها وأولادها الافضل وعشان كده قررنا نساعدها توفق بين بيتها وشغلها وعنايتها بذاتها وتلاقي كل اللي تحتاجه.'}</p>
      </div>
      <div className='momies-card'>
          <div className='icon'><img src={icon} alt="" /></div>
          <h3>{lang==='en'?'Run a free account':'تشغيل حساب مجاني'} :</h3>
          <p>{lang==='en'?'':'عشان عارفين ان صعب تلاقي تطبيق يوفر كل احتياجاتك في جميع جوانب الحياه قررنا نوفرلك حساب مجاني علي تطبيقق ماميز يساعدك في جميع احتياجاتك اليوميه و تنظيم مواعيد و مواعيد اولادك.'}</p>
      </div>
      <div className='momies-card'>
          <div className='icon'><img src={icon} alt="" /></div>
          <h3>{lang==='en'?'':'اضافة مهام يوميه'} :</h3>
          <p>{lang==='en'?'':'لأننا عارفين أنك دايماً مشغولة ومحتارة بين كل المواعيد من شغلك ودروس أولادك وتمارينهم عملنالك جدول ينظملك مواعيدك وينبهك بكل مواعيدك.'}</p>
      </div>
      <div className='momies-card'>
          <div className='icon'><img src={icon} alt="" /></div>
          <h3>{lang==='en'?'':'تتبع الابناء'} :</h3>
          <p>{lang==='en'?'':'عشان تكوني دايما متطمنة على أولادك ومتابعة خطواتهم صممنالك خاصية تتبع تقدري تعرفي من خلالها أماكن تواجدهم لحظة بلحظة.'}</p>
      </div>
      <div className='momies-card'>
          <div className='icon'><img src={icon} alt="" /></div>
          <h3>{lang==='en'?'':'التسوق لجميع الاحتياجات'} :</h3>
          <p>{lang==='en'?'':'عشان نوفرلك وقتك ومجهودك جمعنالك كل حاجة ممكن تحتاجيها ليكي ولبيتك وأولادك في مكان واحد تقدري تتسوقي فيه وتلاقي اللي يناسبك من كل الأختيارات سواء ملابس، أدوات منزلية، إلكترونية، الأدوات الرياضية، أكسسورات وغيرهم.'}</p>
      </div>
      <div className='momies-card'>
          <div className='icon'><img src={icon} alt="" /></div>
          <h3>{lang==='en'?'':'يغطي معظم محافظات الجمهورية'} :</h3>
          <p>{lang==='en'?'':'عشان يهمنا نوفر عليكي البحث عن اقرب الاماكن اللي متواجده فيها وفرنالك كل الخدمات اللي ممكن تحتاجيها في اي مكان واي محافظه '}</p>
      </div>
      <div className='momies-card'>
          <div className='icon'><img src={icon} alt="" /></div>
          <h3>{lang==='en'?'':'العنايه بالذات'} :</h3>
          <p>{lang==='en'?'':'لأنك مهمة و راحتك مهمه بالنسبالنا حابينا نكون معاكي ونساعدك تخلقي الوقت لنفسك بسهولة ونوفرلك كل خدمات العناية بالذات من مراكز العناية بالبشره والشعر والتدريبات الرياضية ومراكز التدريب وتطوير الذات.'}</p>
      </div>
      <div className='momies-card'>
          <div className='icon'><img src={icon} alt="" /></div>
          <h3>{lang==='en'?'':'شات خاص بمحادثات الأمهات'} :</h3>
          <p>{lang==='en'?'':'بنوصلك من خلال تطبيق الماميز بكل الأمهات اللي بتجمعك بيهم أهتمامات مشتركة سواء مدارس أو نوادي أو منطقة سكنية وتقدري تتواصلي مع اي ام او تكونوا جروب خاص بأي شئ يخصكم أنتم أو أولادكم.'}</p>
      </div>
      <div className='momies-card'>
          <div className='icon'><img src={icon} alt="" /></div>
          <h3>{lang==='en'?'':'ندوات و مؤتمرات'} :</h3>
          <p>{lang==='en'?'':'ستجدي مجموعه متنوعه من مختلف المؤتمرات و اللقاءات المختاره بعنايه التي تجعلك علي اضطلاع دائم بما يحدث حولك من موضوعات مختلفه في شتي المجالات الاجتماعيه – الاقتصاديه – العلميه الخ.'}</p>
      </div>
      <div className='momies-card'>
          <div className='icon'><img src={icon} alt="" /></div>
          <h3>{lang==='en'?'':'سهولة الحصول علي خدمه'} :</h3>
          <p>{lang==='en'?'':'مع تطبيق ماميز قررنا نسهل عليكي الحصول علي اي خدمه من الخدمات في مختلف القطاعات سواء خدمات طبيه او تعليميه او مهنيه وغيرهم.'}</p>
      </div>
      <div className='momies-card'>
          <div className='icon'><img src={icon} alt="" /></div>
          <h3>{lang==='en'?'':'ماميز استوديو'} :</h3>
          <p>{lang==='en'?'':'ينقسم استوديو ماميز الي عدة اقسام متنوعه بها كل ما تحتاجيه من دروس ومهارات مختلفه مثل مطبخ ماميز مع مطبخ ماميز هتلاقي كل الوصفات اللي تساعدك و تسهل عليكي وتوفرلك وقتك ومجهودك ومفيده ليكي ول اولادك .'}</p>
      </div>
      <div className='momies-card'>
          <div className='icon'><img src={icon} alt="" /></div>
          <h3>{lang==='en'?'':'توظيف'} :</h3>
          <p>{lang==='en'?'':'لان مهاراتك و تنميتها كمان مهمه بالنسبالنا فا قررنا نساعدك توظفيها صح عن طريق عرض  مهاراتك دي وتستفيدي منها وتوصلي بيها لكل شخص محتاجها أىً كانت المهارة أو الوظيفة اللي بتدوري عليها هتلاقي اللي يناسبك ويناسب خبرتك وسنك ووقتك كل اللي عليكي أنك تعرضي مهاراتك والحاجات اللي بتعرفي تعمليها سواء كانت كبيرة أو بسيطة وهتلاقي فريق من الداعمين اللي قرروا يقدروا كل امرأة ويساعدوها تلاقي وظيفة تناسبها .. تطبيق ماميز بيشجع كل أم.'}</p>
      </div>
      <div className='momies-card'>
          <div className='icon'><img src={icon} alt="" /></div>
          <h3>{lang==='en'?'':'ماميز talks'} :</h3>
          <p>{lang==='en'?'':'يتم تقديم لقاءات حواريه متنوعه مع مقدمي الخدمات عن كل ما يخص المرأه ويهمها في المجالات المختلفه  مثل الطبية – التعليمية – الترفيهية و غيرهم من الموضوعات المفيده لكل ام او امرأه.'}</p>
      </div>
      <div className='momies-card'>
          <div className='icon'><img src={icon} alt="" /></div>
          <h3>{lang==='en'?'':'منصة واحدة لتلبية احتياجات الأمهات بشكل شامل!'} :</h3>
          <p>{lang==='en'?'':'لانك بطلة في حياة اولادك وكل بطل لازم يكون عنده داعم تطبيق الماميز قرر يدعمك ويسهلها عليكي وينظملك كل مواعيدك ويساعدك كمان في متابعة اولادك لحظة بلحظة عشان تكوني دايما متطمنة عليهم.. وكمان وفرنا عليكي تدوري على احتياجاتك علشان كده جمعنالك كل حاجه ممكن تحتاجيها في تطبيق الماميز وده غير خدمات تانية كتيير'}</p>
      </div>
    </>
  )
}
